<template>
  <div>
    <validation-observer v-slot="{ invalid }" ref="single_stand">
      <b-row>
        <b-col md="7" lg="8" xl="8">
          <b-row>
            <b-col md="12" lg="12">
              <b-card-code :title="$t('info')">
                <b-row>
                  <b-col class="col-8">
                    <b-form-group
                      :label="$t('stand_name')"
                      label-for="stand_name"
                    >
                      <b-form-input
                        id="stand_name"
                        :placeholder="$t('insert_name')"
                        v-model="stand_name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="col-4">
                    <b-form-group
                      :label="$t('Position') + ' *'"
                      label-for="stand_number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer|required"
                        name="Number"
                      >
                        <b-form-input
                          id="stand_number"
                          v-model="external_ref"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('insert_number')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12">
                    <b-form-group
                      :label="$t('referent_email')"
                      label-for="referent_email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="email"
                      >
                        <b-form-input
                          id="referent_email"
                          v-model="manager_email"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('add_email')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12">
                    <b-form-group
                      :label="$t('short_description')"
                      label-for="stand_description"
                    >
                      <b-form-textarea
                        id="stand_description"
                        v-model="description"
                        :placeholder="$t('add_short_description')"
                        rows="4"
                        :state="statusColor"
                        class="char-textarea"
                        maxlength="350"
                        :class="
                          description.length >= maxChar ? 'text-danger' : ''
                        "
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="
                          description.length >= maxChar ? 'bg-danger' : ''
                        "
                      >
                        <span class="char-count">{{ description.length }}</span>
                        /
                        {{ maxChar }}
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-code>
            </b-col>
          </b-row>
          <!-- Loghi Upload -->
          <b-row class="logo-stand">
            <b-col md="6" lg="6">
              <b-card :title="$t('logo_stand')">
                <b-card-text>
                  {{ $t("main_logo_stand") }}
                </b-card-text>
                <b-form-file
                  accept=".jpg, .png"
                  ref="file-input"
                  :placeholder="$t('any_file_select')"
                  v-model="logo_stand"
                  @input="openEditor(logo_stand, 'logo_stand')"
                />
                <b-img
                  :src="preview_logo_stand"
                  id="preview-logo-stand"
                  class="image-preview"
                />
                <b-button id="open-editor" v-b-modal="'modal-editor'" hidden />
              </b-card>
            </b-col>
            <b-col md="6" lg="6">
              <b-card :title="$t('logo_floor')">
                <b-card-text>
                  {{ $t("negative_logo") }}
                </b-card-text>
                <b-form-file
                  accept=".jpg, .png"
                  ref="file-input"
                  :placeholder="$t('any_file_select')"
                  v-model="logo_floor"
                  @input="openEditor(logo_floor, 'logo_floor')"
                />
                <b-img
                  :src="preview_logo_floor"
                  id="preview-logo-floor"
                  class="image-preview"
                />
              </b-card>
            </b-col>
          </b-row>
          <!-- Gestione Pin Point -->
          <b-row>
            <b-col md="12" lg="12">
              <b-card-code :title="$t('pin_point_management')">
                <b-table
                  hover
                  small
                  :fields="fields"
                  :items="pinpoints"
                  responsive="sm"
                  class="table_stand"
                >
                  <template #cell(index)="data" class="test">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.item.label }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge v-if="data.item.active" pill variant="success">
                      {{ $t("status_active") }}
                    </b-badge>
                    <b-badge v-else pill variant="danger">
                      {{ $t("status_inactive") }}
                    </b-badge>
                  </template>
                  <template #cell(action)="data">
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      @click="
                        $bvModal.show(
                          'modal-' + data.item.type.slug,
                          data.item.fields
                        )
                      "
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                </b-table>
              </b-card-code>
            </b-col>
          </b-row>

          <!-- Personalizzazioni -->
          <b-row>
            <b-col md="12" lg="12" class="table_stand">
              <b-card-code :title="$t('media')">
                <b-table
                  hover
                  small
                  :fields="fields_customizations"
                  :items="media"
                  responsive="sm"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(name)="data">
                    {{
                      data.item.type.slug.charAt(0).toUpperCase() +
                        data.item.type.slug.slice(1)
                    }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge v-if="data.item.active" pill variant="success">
                      {{ $t("status_active") }}
                    </b-badge>
                    <b-badge v-else pill variant="danger">
                      {{ $t("status_inactive") }}
                    </b-badge>
                  </template>
                  <template #cell(action)="data">
                    <b-button
                      v-b-modal="'modal-' + data.item.type.slug"
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                </b-table>
              </b-card-code>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" lg="4" xl="4">
          <b-card
            :img-src="logo_preview"
            img-alt="Profile Cover Photo"
            img-top
            class="card-developer-meetup stand-dx"
          >
            <h3>{{ stand_name }}</h3>
            <h6 class="text-muted">
              {{ $t("pavilion_ref") }}: {{ pavilion_ref }}
              <span class="stand_type">{{ stand_type }}</span>
            </h6>
            <hr class="mb-2" />

            <!-- follower projects rank -->
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Pin
                </h6>
                <vue-apex-charts
                  type="radialBar"
                  height="100"
                  width="60"
                  :options="gaugeOverviewRadialBar.chartOptions"
                  :series="gauge_pin"
                />
                <span class="font-weight-bold text-body-heading mr-1"
                  >{{ customization_data[0].pin_usage }} /
                  {{ customization_data[0].pin_total }}</span
                >
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Media
                </h6>
                <vue-apex-charts
                  type="radialBar"
                  height="100"
                  width="60"
                  :options="gaugeOverviewRadialBar.chartOptions"
                  :series="gauge_media"
                />
                <span class="font-weight-bold text-body-heading mr-1"
                  >{{ customization_data[1].media_usage }} /
                  {{ customization_data[1].media_total }}</span
                >
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Logo
                </h6>
                <vue-apex-charts
                  type="radialBar"
                  height="100"
                  width="60"
                  :options="gaugeOverviewRadialBar.chartOptions"
                  :series="gauge_logo"
                />
                <span class="font-weight-bold text-body-heading mr-1"
                  >{{ customization_data[2].logo_usage }} /
                  {{ customization_data[2].logo_total }}</span
                >
              </div>
            </div>
            <hr class="mb-2" />

            <b-card-text class="col-form-label">{{
              $t("stand_link")
            }}</b-card-text>
            <div class="d-flex stand-link">
              <!-- form input -->
              <b-form-group class="mb-0 mr-1">
                <b-form-input
                  v-model="stand_url"
                  disabled
                  :placeholder="$t('placeholder_link_stand')"
                />
              </b-form-group>

              <b-button
                v-clipboard:copy="stand_url"
                v-clipboard:success="onCopyClip"
                v-clipboard:error="onErrorClip"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
              >
                {{ $t("copy") }}
              </b-button>
            </div>

            <b-form-group :label="$t('State')" class="mt-2">
              <b-form-select v-model="active" :options="state_options" />
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              class="mt-2"
              @click.prevent="saveData"
              :disabled="invalid"
            >
              {{ $t("save_edit") }}
            </b-button>
            <!--/ follower projects rank -->
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>

    <!-- pinpoints -->
    <component
      v-for="pinpoint in pinpoints"
      :ref="pinpoint.type.slug"
      :key="'pinpoint_' + pinpoint.id"
      :is="'pin-point-' + pinpoint.type.slug"
      :pinpoint="pinpoint"
      @open_editor="openEditor"
      @onSave="refreshPinpoint"
    ></component>

    <!-- media -->
    <component
      v-for="medium in media"
      :key="'media_' + medium.id"
      :ref="medium.type.slug"
      :is="medium.type.slug"
      :media="medium"
      @open_editor="openEditor"
      @onSave="refreshMedia"
    ></component>

    <modal-editor
      :image="image"
      :type="type"
      :width="width"
      :height="height"
      @onSave="showPreview"
      @close="abortImageEdit"
    ></modal-editor>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BBadge,
  BTable,
  BFormFile,
  BFormTextarea,
  BButton,
  BCard,
  BCardText,
  BLink,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BModal,
  VBModal,
  BFormSelect,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, integer } from "@validations";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

//import pinpoint
import PinPointSite_link from "./pin_point/logo";
import PinPointContacts from "./pin_point/contacts";
import PinPointSocial from "./pin_point/social";
import PinPointCompany from "./pin_point/company";
import PinPointIndustry from "./pin_point/industry";
import PinPointProgram from "./pin_point/program";
import PinPointWorkshop from "./pin_point/workshop-live";
import PinPointOn_demand_webinar from "./pin_point/webinar-on-demand";
import PinPointCompany_webinar from "./pin_point/webinar-company";
import PinPointCustom_1 from "./pin_point/free-content-1";
import PinPointCustom_2 from "./pin_point/free-content-2";
import PinPointPromo from "./pin_point/promo";

//import personalization
import Screen from "./personalization/screen";
import Preview from "./personalization/preview";
import Totem from "./personalization/totem-preview";

//import photo editor
import ModalEditor from "../editor.vue";

import { getStand, updateStand } from "@api/stands";
import { getMediaByStand } from "@api/media";
import { getPinpointsByStand } from "@api/pinpoint";

import { baseURL } from "@variables";

export default {
  components: {
    BCardCode,
    BModal,
    BBadge,
    BTable,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BImg,
    ToastificationContent,
    PinPointSite_link,
    PinPointContacts,
    PinPointSocial,
    PinPointCompany,
    PinPointIndustry,
    PinPointProgram,
    PinPointWorkshop,
    PinPointOn_demand_webinar,
    PinPointCompany_webinar,
    PinPointCustom_1,
    PinPointCustom_2,
    PinPointPromo,
    ModalEditor,
    BAvatar,
    Screen,
    Preview,
    Totem,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stand: null,
      preview_logo_stand: null,
      preview_logo_floor: null,
      gaugeOverviewRadialBar: {
        chartOptions: {
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      gauge_pin: [],
      gauge_media: [],
      gauge_logo: [],
      customization_data: [
        {
          name: "Pin",
          pin_usage: null,
          pin_total: null,
        },
        {
          name: "Media",
          media_usage: null,
          media_total: null,
        },
        {
          name: "Logo",
          logo_usage: null,
          logo_total: 2,
        },
      ],
      logo_preview: null,
      image: null,
      type: null,
      width: 0,
      height: 0,
      stand_name: "",
      original: {
        stand_name: "",
        external_ref: "",
        manager_email: "",
        description: "",
        active: "",
      },
      external_ref: "",
      manager_email: "",
      description: "",
      logo_stand: null,
      logo_floor: null,
      pavilion_ref: "",
      maxChar: 350,
      stand_type: "Big",
      stand_url: "www.google.it",
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("field_label_name") },
        { key: "status", label: this.$t("field_label_status") },
        { key: "action", label: this.$t("field_label_actions") },
      ],
      fields_customizations: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("field_label_name") },
        { key: "status", label: this.$t("field_label_status") },
        { key: "action", label: this.$t("field_label_actions") },
      ],
      pinpoints: [],
      media: [],
      active: false,
      state_options: [
        { value: true, text: this.$t("status_published") },
        { value: false, text: this.$t("status_inactive") },
      ],
      crop_limit: {
        logo_stand: {
          width: 840,
          height: 300,
        },
        logo_floor: {
          width: 840,
          height: 300,
        },
        contacts: {
          width: 160,
          height: 160,
        },
        totem: {
          width: 300,
          height: 450,
        },
        preview: {
          width: 1280,
          height: 720,
        },
        screen: {
          width: 1280,
          height: 720,
        },
      },
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    this.getData();
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkIfDataAreChanged()) {
      next(false);
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("alert_save_changes"),
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_go_next_page"),
        cancelButtonText: this.$t("alert_stay_on_page"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            next();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      next();
    }
  },
  methods: {
    getData() {
      const $this = this;
      getStand(this.$route.params.id)
        .then((data) => {
          $this.initializeStandData(data);
        })
        .catch(() => {
          this.$router.push({ path: `/error-404/` });
        });
    },
    initializeStandData(data) {
      //TODO: refactory usare this.stand per i dati dello stand
      this.stand = data;
      this.stand_name = data.name;
      this.original.stand_name = data.name;
      //HACK: per modificarlo in modo semplice
      document.getElementById("breadcrumbsPageTitle").innerHTML = data.name;
      this.external_ref = data.external_ref;
      this.original.external_ref = data.external_ref;
      this.manager_email = data.manager_email;
      this.original.manager_email = data.manager_email;
      this.description = data.description;
      this.original.description = data.description;
      this.active = data.active;
      this.original.active = data.active;
      if (data.logo != "") {
        this.showPreview("logo_stand", data.logo_url);
      }
      if (data.floor_logo != "") {
        this.showPreview("logo_floor", data.floor_logo_url);
      }
      this.pavilion_ref = data.pavilion_ref;
      this.stand_type = data.type.label;
      this.stand_url = this.createStandUrl(data.panorama, data.pov);
      this.pinpoints = data.pinpoints;
      this.media = data.media;
      this.setPinGauge();
      this.setMediaGauge();
      this.setLogoGauge();
    },
    createStandUrl(stand_panorama, stand_pov) {
      let pov_list = {
        2: 0,
        4: 90,
        6: 180,
        8: 270,
      };
      let media = `${stand_panorama.row.toUpperCase()}${(
        "0" + stand_panorama.column.toString()
      ).slice(-2)}`;
      return `${baseURL}/?panorama=${media}&pov=${pov_list[stand_pov]}&preview=1`;
    },
    setPinGauge() {
      this.gauge_pin = [];
      this.customization_data[0].pin_usage = this.countActiveCustomizations(
        this.pinpoints
      );
      this.customization_data[0].pin_total = this.pinpoints.length;
      this.setPercentageGauge(this.customization_data[0]);
    },
    setMediaGauge() {
      this.gauge_media = [];
      this.customization_data[1].media_usage = this.countActiveCustomizations(
        this.media
      );
      this.customization_data[1].media_total = this.media.length;
      this.setPercentageGauge(this.customization_data[1]);
    },
    setLogoGauge() {
      this.gauge_logo = [];
      let logo_count = 0;
      if (this.logo_preview != null) {
        logo_count++;
      }
      if (this.preview_logo_floor != null) {
        logo_count++;
      }
      this.customization_data[2].logo_usage = logo_count;
      this.setPercentageGauge(this.customization_data[2]);
    },
    countActiveCustomizations(data) {
      let count = 0;
      for (let i = 0; i < data.length; ++i) {
        if (data[i].edited) {
          count++;
        }
      }
      return count;
    },
    setPercentageGauge(data) {
      if (data.name == "Pin") {
        this.gauge_pin.push(
          Math.round((data.pin_usage / data.pin_total) * 100)
        );
      } else if (data.name == "Media") {
        this.gauge_media.push(
          Math.round((data.media_usage / data.media_total) * 100)
        );
      } else if (data.name == "Logo") {
        this.gauge_logo.push(
          Math.round((data.logo_usage / data.logo_total) * 100)
        );
      }
    },
    checkIfDataAreChanged() {
      if (
        this.stand_name == this.original.stand_name &&
        this.external_ref == this.original.external_ref &&
        this.manager_email == this.original.manager_email &&
        this.description == this.original.description &&
        this.active == this.original.active &&
        !this.logo_stand &&
        !this.logo_floor
      ) {
        return false;
      } else {
        return true;
      }
    },
    openEditor(file, type) {
      if (file != null) {
        this.image = file;
        this.type = type;
        this.width = this.crop_limit[type].width;
        this.height = this.crop_limit[type].height;
        this.$bvModal.show("modal-editor");
      }
    },
    showPreview(type, imageUrl, image = null) {
      if (type == "logo_stand") {
        this.logo_stand = image;
        this.preview_logo_stand = imageUrl;
        this.logo_preview = imageUrl;
      } else if (type == "logo_floor") {
        this.logo_floor = image;
        this.preview_logo_floor = imageUrl;
      } else if (type == "contacts") {
        this.$refs.contacts[0].file = image;
        this.$refs.contacts[0].preview_contacts = imageUrl;
      } else if (type == "preview") {
        this.$refs.preview[0].preview_file = image;
        this.$refs.preview[0].preview_container = imageUrl;
      } else if (type == "totem") {
        this.$refs.totem[0].totem_file = image;
        this.$refs.totem[0].preview_totem = imageUrl;
      } else if (type == "screen") {
        this.$refs.screen[0].image_file = image;
        this.$refs.screen[0].preview_screen = imageUrl;
      }
    },
    abortImageEdit(type) {
      if (type == "logo_stand") {
        this.logo_stand = null;
      } else if (type == "logo_floor") {
        this.logo_floor = null;
      } else if (type == "contacts") {
        this.$refs.contacts[0].emptyField();
      } else if (type == "preview") {
        this.$refs.preview[0].emptyField();
      } else if (type == "totem") {
        this.$refs.totem[0].emptyField();
      } else if (type == "screen") {
        this.$refs.screen.emptyField();
      }
    },
    onCopyClip() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("text_copied"),
          icon: "BellIcon",
        },
      });
    },
    onErrorClip() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("failed_text_copied"),
          icon: "BellIcon",
        },
      });
    },
    refreshPinpoint() {
      getPinpointsByStand(this.$route.params.id)
        .then((data) => {
          this.pinpoints = data;
          this.setPinGauge();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshMedia() {
      getMediaByStand(this.$route.params.id)
        .then((data) => {
          this.media = data;
          this.setMediaGauge();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      const $this = this;
      this.$refs.single_stand.validate().then((success) => {
        if (success) {
          $this.save();
        }
      });
      return false;
    },
    save() {
      const $this = this;
      let formData = new FormData();
      formData.append("name", $this.stand_name);
      formData.append("manager_email", $this.manager_email);
      formData.append("external_ref", $this.external_ref);
      formData.append("description", $this.description);
      if ($this.logo_stand) {
        formData.append("logo", $this.logo_stand);
      }
      if ($this.logo_floor) {
        formData.append("floor_logo", $this.logo_floor);
      }
      formData.append("active", $this.active ? 1 : 0);
      updateStand(this.$route.params.id, formData)
        .then((data) => {
          $this.$toast({
            component: ToastificationContent,
            props: {
              title: $this.$t("save_complete"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          $this.initializeStandData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    statusColor() {
      if (this.description.length > this.maxChar) {
        return false;
      } else if (this.description.length > 0) {
        return true;
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
.preview-img img {
  width: 200px;
  position: relative;
  left: 50%;
  top: 50%;
  padding-top: 10px;
  transform: translate(-50%, 0%);
}
</style>
