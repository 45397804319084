<template>
  <app-collapse>
    <app-collapse-item :title="$t('pin_point.Icon_selector')">
      <vfa-picker v-model="icon">
        <template v-slot:activator="{ on }">
          <b-form-group label="Icona" label-for="icon">
            <b-form-input
              id="icon"
              v-model="icon"
              @click="on"
              placeholder="Icon"
              type="text"
            />
          </b-form-group>
        </template>
      </vfa-picker>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { BFormInput, BFormGroup, BForm } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "pin-point-icon-selector",
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    AppCollapse,
    AppCollapseItem,
  },
  props: ["value"],
  data() {
    return {
      icon: this.value,
    };
  },
  watch: {
    icon: function() {
      this.$emit("input", this.icon);
    },
    value() {
      //HACK senza di questo non aggiornava i data
      this.icon = this.value;
    },
  },
};
</script>

<style></style>
