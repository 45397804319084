<template>
  <div>
    <b-modal
      id="modal-totem"
      :ok-title="$t('Save')"
      :cancel-title="$t('Abort')"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      hideHeaderClose
      @ok.prevent="saveData"
      @cancel="resetModal"
      @show="showModal"
      size="lg"
      centered
    >
      <b-overlay :show="show" rounded="sm">
        <b-row>
          <b-col cols="12" class="modalTitle">
            <h2 class="font-weight-bolder">
              {{ $t("pin_point.frontal_totem") }}
            </h2>
            <span>{{ $t("pin_point.frontal_totem_description") }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="media-input-column">
            <b-form-group :label="$t('pin_point.frontal_totem_label')">
              <b-form-file
                accept=".jpg, .png"
                id="totem_file"
                v-model="totem_file"
                @input="openEditorPreviewTotem(totem_file, 'totem')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="column-preview-container">
            <b-img
              :src="preview_totem"
              id="totem-preview-container"
              class="preview-img"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="pin_hide">
            <footer-pin-point v-model="hide_media"></footer-pin-point>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  VBModal,
  BModal,
  BFormFile,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "../pin_point/footer";
import { updateMedia } from "@api/media";

export default {
  props: ["media"],
  name: "totem",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BImg,
    BOverlay,
    FooterPinPoint,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      image: null,
      type: null,
      totem_file: null,
      preview_totem: null,
      fields: { ...this.media.fields },
      hide_media: !this.media.active,
      show: false,
    };
  },
  methods: {
    showModal() {
      if (this.media.fields.image) {
        this.preview_totem = this.media.fields.image.url;
      }
    },
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("image", $this.totem_file);
      formData.append("active", $this.hide_media ? 0 : 1);
      updateMedia($this.media.id, formData)
        .then((data) => {
          this.$emit("onSave");
          $this.$bvModal.hide("modal-totem");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-totem");
      this.fields = { ...this.media.fields };
      this.hide_media = !this.media.active;
      this.preview_totem = null;
    },
    openEditorPreviewTotem(file, type) {
      this.$emit("open_editor", file, type);
    },
    emptyField() {
      this.totem_file = null;
    },
  },
};
</script>

<style></style>
